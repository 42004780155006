@import "src/content/styles/variables";

.push-notification-button {
  width: auto;
  svg {
    fill: $color-primary;
    width: 20px;
    height: 20px;

    &.large {
      width: 24px;
      height: 24px;
    }
  }

  &:not([disabled]) svg {
    &.bell-off {
      fill: $color-gray-45;

      &:hover {
        fill: $color-primary;
      }
    }
    &.bell-active {
      fill: $color-primary;
  
      &:hover {
        fill: $color-primary-dark;
      }
    }
  }
}
