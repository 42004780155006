@import "src/content/styles/embla-variables";

#notifikation-overview-modal {
  .modal-header {
      border-bottom: 0;
      background-color: $color-primary-dark;
      color: $color-tertiary-dark;
      .modal-title {
        color: $color-white;
      }
      .btn-icon {
        svg.embla-icon {
          fill: $color-white;
        }
    }
  }
  .modal-body {
    padding: 0;
  }


  .sticky-top-notifikationer {
    position: sticky;
    padding: $padding-default;
    background: $color-white;
    top: 0;
    z-index: 3;
    border-bottom: 3px solid $color-gray-85;
  }
}
